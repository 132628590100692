<template>
  <el-row class="border" id="gradePrint">
    <el-col
      :span="24"
      class="style-right h-100"
      ref="main"
      id="mians"
      :style="{ backgroundImage: 'url(' + bgimgUrl + ')' }"
      style="min-height: 600px"
    >
      <div wx class="w-100 cjd_title" style="white-space: pre-wrap">
        {{ library.cjdmc }}
      </div>
      <div class="w-100">
        <div
           v-if="library.cjdzysx"
          class="text-dark"
          style="
            padding-left: 20px;
            white-space: pre-wrap;
          "
        >
          {{ library.cjdzysx }}
        </div>
      </div>
      <div class="mt-3 exam-form" style="min-height: 40%; max-height: 80%">
        <el-row style="" class="border-diff top-height" v-if="zpxs">
          <el-col class="guding-top">
            <div
              ref="list1Container"
              class="flexList guding-col"
              :list="list2"
              group="people"
              style="min-height: 40px"
            >
              <div
                class="flexList msgLabel"
                :class="[
                  'w' + element.zdbl,
                  element.txlx == 'textarea' ? 'msgtextarea' : '',
                ]"
                v-for="element in list2"
                :key="element.sid"
              >
                <div class="style-name mr-2">
                  {{ element.zdymc ? element.zdymc : element.zdmc }}：
                </div>
                <div class="style-val flexList">
                  {{ grade[element.zddm] }}
                </div>
              </div>
            </div>
          </el-col>
          <el-col class="guding-img">
            <img :src="imgUrl" alt="" style="width: 120px; height: 150px" />
          </el-col>
        </el-row>
        <el-row>
          <div
            style="min-height: 40px; width: 100%; flex-wrap: wrap"
            class="flexList fs-xs less-width"
            :list="list3"
            group="people"
          >
            <div
              class="flexList msgLabel"
              :class="[
                'w' + element.zdbl,
                element.txlx == 'textarea' ? 'msgtextarea' : '',
              ]"
              v-for="element in list3"
              :key="element.sid"
            >
              <div class="style-name mr-2">
                {{ element.zdymc ? element.zdymc : element.zdmc }}：
              </div>
              <div class="style-val flexList">
                {{ grade[element.zddm] }}
              </div>
            </div>
          </div>
        </el-row>
      </div>
      <div v-if="library.cjdbzxx" class="w-100 mb-3" id="scoreBottom">
        <div class="bottom-info text-dark"  style="padding-left: 20px;white-space: pre-wrap;
">
          {{ library.cjdbzxx }}
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import draggable from "vuedraggable";
import $ from "jquery";
export default {
  components: {
    draggable,
  },
  props: {
    list: Array,
    grade: Object,
    library: Object,
    zpxs: Boolean,
    sfhxbj: Boolean,
  },
  data() {
    return {
      list2: [],
      list3: [],
      imgUrl: "",
      bgimgUrl: "",
    };
  },
  methods: {
    listfill() {
      this.list.forEach((item) => {
        item.zddm = item.zddm.replace("_", "");
        if (this.zpxs) {
          if (item.zdfl == 1) {
            this.list2.push(item);
          } else {
            this.list3.push(item);
          }
        } else {
          this.list3.push(item);
        }
      });
    },
  },
  watch: {
    sfhxbj: {
      handler(val) {
        if (this.sfhxbj) {
          this.moduleType = 1;
          $("#gradePrint").css("width", "1103px");
          // $(".card").css("width", '1103px')
          let height = Math.floor((1103 / 297) * 210);
          $("#gradePrint").css("height", height + "px");
        } else {
          this.moduleType = 0;
          $("#gradePrint").css("width", "780px");
          // $(".card").css("width", '780px')
          let height = Math.floor((780 / 210) * 297);
          $("#gradePrint").css("height", height + "px");
        }
      },
    },
    library: {
      handler(data) {
        if (data.cjbjwj) {
          this.getFileUrl(data.cjbjwj, true).then((res) => {
            if (res.status) {
              this.bgimgUrl = res.data;
            }
          });
        }
      },
    },
    grade: {
      handler(form) {
        let url = "";
        if (form.zpdz) {
          url = form.zpdz;
        } else {
          if (this.library.ksbmbh) {
            url = "bm_" + this.library.ksbmbh + "_" + form.zjhm;
          } else {
            url = "cjk_" + form.cjkid + "_" + form.zjhm;
          }
        }
        this.getFileUrl(url, true).then((res) => {
          if (res.status) {
            this.imgUrl = res.data;
          }
        });
      },
    },
    list: {
      immediate: true,
      handler(list) {
        this.listfill();
      },
    },
  },
};
</script>

<style scoped>
@import "~@/assets/css/admin.css";

#gradePrint {
  margin: 0 auto;
}
body {
  font-size: 16px;
}

.w-99 {
  width: 99%;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.style-set-box {
  border-bottom: unset;
}

.less-width .w50 {
  width: 40%;
}

.less-width .w50:nth-child(2n) {
  width: 60% !important;
}

.guding-top {
  width: 80%;
}
.exam-form {
  padding-left: 20px;
}

.top-height {
  /* max-height: 160px; */
  /* line-height: 160px; */
  display: flex;
}

.msgLabel {
  color: #000000;
}
.cjd_info {
  width: 100%;
  margin: -1px 0 0 -1px;
}

.bottom-info {
  max-height: 100px;
  padding-left: 20px;
}

.guding-col {
  flex-wrap: wrap;
  box-sizing: border-box;
}

.guding-col .msgLabel:nth-child(n-1) {
  border-bottom: unset;
}

.score-list .msgName {
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-border-bottom {
  border-bottom: unset;
}
.cjd_title {
  width: 100%;
  /* height: 50px; */
  /* line-height: 50px; */
  font-size: 24px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  color: #000000;
  /* line-height: normal; */
}

.guding-img {
  width: 20%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: unset;
}

.style-right {
  background-size: 100% 100%;
  /*min-height: 500px;*/
  font-size: 18px;
  padding: 0 25px;
  background-repeat: no-repeat;
}
.msgLabel {
  border-right: unset;
}
</style>
